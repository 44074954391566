/*@import '~antd/dist/antd.css';*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.submit-button-red {
  background-color: #d93e23;
  background: #d93e23;
  border: 1px #d14c1f solid;
  color: #FFFFFF;
}

.mystyle-title-user-profile {
  position: relative;
  float: right;
  width: 500px;
  height: 40;
  margin: 2px;
  font-size: 12px;
}

.mystyle-title-user-profile-data {
  font-weight: bolder;
  font-size: 12px;
}

.expand-row {
  margin: '5px 10px 5px 10px';
}
.expand-row-content {
  margin: '5px 10px 5px 10px';
}

.pg-subtitles {
  font-size: 16px;
  margin: 2px 5px 2px 5px;
  font-weight: bolder;
}

.loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/****** Icons *****/
.icon-reload{
	background:url('media/images/icons/reload.png') no-repeat center center;
}
.icon-search{
	background:url('media/images/icons/search.png') no-repeat center center;
}
.icon-man{
	background:url('media/images/icons/man.png') no-repeat center center;
}

.icon-excel{
	background:url('media/images/icons/excel.png') no-repeat center center;
}
.icon-two-men{
	background:url('media/images/icons/two-men.png') no-repeat center center;
}

.icon-report2{
	background:url('media/images/icons/report2.png') no-repeat center center;
}

.icon-events{
	background:url('media/images/icons/events.png') no-repeat center center;
}

.icon-logout{
	background:url('media/images/icons/exit.png') no-repeat center center;
}

.icon-airtel-money{
	background:url('media/images/icons/AirtelSmallLogo.png') no-repeat center center;
}

.icon-business_id{
	background:url('media/images/icons/business_id.png') no-repeat center center;
}

.icon-mtn-mobile-money{
	background:url('media/images/icons/mtn_mobile_money_logo.png') no-repeat center center;
}

.icon-settings{
	background:url('media/images/icons/settings.png') no-repeat center center;
}
.icon-dollars {
	background:url('media/images/icons/dollars.png') no-repeat center center;
}

.icon-banknote {
	background:url('media/images/icons/banknote.png') no-repeat center center;
}
.icon-money {
	background:url('media/images/icons/money.png') no-repeat center center;
}
.icon-approve {
	background:url('media/images/icons/approve.png') no-repeat center center;
}
.icon-decline {
	background:url('media/images/icons/decline.png') no-repeat center center;
}
.icon-iphone {
	background:url('media/images/icons/iphone.png') no-repeat center center;
}
.icon-dashboard {
	background:url('media/images/icons/dashboard.png') no-repeat center center;
}

.icon-media-start {
	background:url('media/images/icons/media-start.png') no-repeat center center;
}

.icon-details {
	background:url('media/images/icons/details.png') no-repeat center center;
}

.icon-report{
	background:url('media/images/icons/report.png') no-repeat center center;
}